<template>
  <div>
    <getecma-header size="md">Oops!</getecma-header>
    <getecma-header>Algo deu errado</getecma-header>
    <getecma-p>Por favor, tente recarregar a página ou entre em contato com o suporte</getecma-p>
    <getecma-button class="btn btn-primary" @click="goToBasePage()">
      Voltar à página
    </getecma-button>
  </div>
</template>

<script>
import { goToBasePage } from '@/router/route.service';

export default {
  name: 'GetecmaGenericErrorPage',
  methods: {
    goToBasePage,
  },
};
</script>
